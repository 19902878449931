export const SiteRoutes = {
    Home: { path: "/" },
    Play: { path: "/play" },
    Login: { path: "/login" },

    Map: { path: "/map" },

    Contact: { path: "/contact" },
    PrivacyPolicy: { path: "/privacy_policy" },


    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};