import React, { useEffect, useState } from 'react'
import '../styles/board.css'
import { Board } from '../components/board';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useData } from '../services/firebase';
import { SiteRoutes } from '../routes';
import {isOver} from '../utils/logic'
import Modal from 'react-bootstrap/Modal';

const Play = () => {
  const [boardWidth, setWidth] = useState(Math.min(window.innerWidth,window.innerHeight*7/8)*.80)
  const { curBoard, setCurBoard } = useData()
  const [mutBoardId, setmutBoardId] = useState(curBoard)
  const [boardIdUpdatedCallback, setBoardIdUpdatedCallback] = useState()
  const navigate = useNavigate()
  const [wasReset, setWasReset] = useState(false)
  const [winner, setWinner] = useState(0)

  const handleResize = e => {
    setWidth(Math.min(window.innerWidth,window.innerHeight*7/8)*.80)
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setCurBoard(localStorage.getItem("boardId"))
    setmutBoardId(localStorage.getItem("boardId"))
    console.log(mutBoardId)
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);

  setTimeout(function () {
    if(!boardIdUpdatedCallback || wasReset) return

    requestAnimationFrame(() => boardIdUpdatedCallback(mutBoardId))
    console.log("callback set from parent " + mutBoardId)
    setWasReset(true);
  })

  function onBoardUpdated(board)
  {
    const over = isOver(board)
    if(over !== 0)
    {
      setWinner(over)
    }
  }

  console.log(curBoard)

  return (
    <>
    <Modal as={Modal.Dialog} centered show={winner !== 0}>
          <div style={{background: "#111", bordercolor: "grey", borderWidth: 1, borderStyle: "solid", borderRadius: 6}}>
          <Modal.Body>
          <h3 className="mt-4 Auth-form-title">{winner === 1 ? "Black" : "White"} Wins!</h3>
          <div  className="center-screen-noresize">
            {/* <Button className="mt-2 mx-4 mb-4" variant="primary" onClick={()=> { setCurBoard(""); navigate(SiteRoutes.Home.path)}}>
              Replay
            </Button> */}
            <Button className="mt-2 mx-4 mb-4" variant="secondary" onClick={()=> { setCurBoard(""); navigate(SiteRoutes.Home.path)}}>
              Exit
            </Button>
          </div>
          </Modal.Body>
          {/* <Modal.Footer>
          </Modal.Footer> */}
          </div>
        </Modal>
    <div  className="center-screen">
      <Board style={{ marginLeft: boardWidth/2}} boardWidth={boardWidth} boardId={mutBoardId} setBoardIdUpdatedCallback={setBoardIdUpdatedCallback} onBoardUpdatedCallback={onBoardUpdated}/>
      <Button style={{ position: "absolute", display: "block", marginTop: (boardWidth+100) }} variant="secondary" onClick={()=> { setCurBoard(""); navigate(SiteRoutes.Home.path)}}>
        Exit
      </Button>
    </div>
    </>
  )
}

export default Play
