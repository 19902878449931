import { initializeApp, firebase } from "firebase/app";

import {getFirestore, collection, doc, setDoc, deleteField  , getCountFromServer, getDocs, getDoc, updateDoc, onSnapshot, query, where, limit, Firestore } from "firebase/firestore"; 
// import {db} from '../firebase';

import React, { useContext, useState, useEffect } from "react";
import { getAuth, signOut, updateProfile, createUserWithEmailAndPassword, OAuthProvider, signInAnonymously, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithRedirect, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SiteRoutes } from "../routes";
import { rankBoard } from "../utils/logic";

const firebaseConfig = {
  apiKey: "AIzaSyBgXt_qv5YwwRmWb46m9_PVA7Q5BcCbI5I",
  authDomain: "five-knights.firebaseapp.com",
  projectId: "five-knights",
  storageBucket: "five-knights.appspot.com",
  messagingSenderId: "823074359109",
  appId: "1:823074359109:web:eb6dab9c34ee977fa8202c",
  measurementId: "G-ZQS4ERHZVC"
};

const specialUsernameWasSetString = "${telsa}"

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
    const navigate = useNavigate(); 

    const [user, setUser] = useState(null)
    const [myAccount, setMyAccount] = useState(null)
    const [curBoard, setCurBoard] = useState("")
    const [callback2, setCallback] = useState()
    const [myMatches, setMyMatches] = useState([])
    const [curBoardState, setCurBoardState] = useState(undefined)
    const [num, setNum] = useState(0)

    async function updateState(board,turn,boardId){
        console.log(boardId)
        const theirId = boardId.split("|")[0] == getMyUsername() ? boardId.split("|")[1] : boardId.split("|")[0]
        
        const docRefOurs = doc(db,"Users", getMyUsername(), "Extra", "match_0");
        const docRefTheirs = doc(db,"Users", theirId, "Extra", "match_0");
        
        let state  = rankBoard(board, turn)
        // {}
        // for(let i = 0; i < 8; i++)
        // {
        //     state[i] = board[i]
        // }
        
        const res = await updateDoc(docRefOurs, { [`${boardId}.state`]: state, [`${boardId}.turn`]: turn })
        const res2 = await updateDoc(docRefTheirs, { [`${boardId}.state`]: state, [`${boardId}.turn`]: turn })

        console.log("update done: " + res)
        console.log("update done: " + res2)
    }

    const board = [
        [0, 1, 1, 3, 1, 1, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0],
        [0, 2, 2, 4, 2, 2, 0],
    ]


    async function createMatch(theirId)
    {
        let turn = 1
        let state  = rankBoard(board, turn)
        // {}
        // for(let i = 0; i < 8; i++)
        // {
        //     state[i] = board[i]
        // }

        const username = getMyUsername();

        const boardNum = getNextMatchNumber(username, theirId)

        const boardId = username+"|"+theirId+"|"+boardNum
          
        const docRefOurs = doc(db,"Users", username, "Extra", "match_0");
        const docRefTheirs = doc(db,"Users", theirId, "Extra", "match_0");
        console.log("A")
        const res = await setDoc(docRefOurs, { [boardId]: {state:state, turn:turn, isWhite: true, mode: 1} }, { merge: true })
        const res2 = await setDoc(docRefTheirs, { [boardId]: {state:state, turn:turn, isWhite: false, mode: 1} }, { merge: true })

        console.log("create done: " + res)
        console.log("create done: " + res2)

        setCurBoard(boardId)
        localStorage.setItem("boardId", boardId)
        navigate(SiteRoutes.Play.path);
    }

    function userObject(username, bio, uid) 
    {
        return {
            uid: uid,
            username: username,
            bio: bio,
            elo: 1200.0,
            lastSeen: Date.now(),
            isOnline: false,
            usernameLower: username.toLowerCase()
        }
    }

    useEffect(() => {

        onAuthStateChanged(auth, (user) => {
            console.log("auth state" + user)
            setUser(user)

            if(user !== null) listenToAccount();
            else setMyAccount(null)
        });

    }, []);
    
    const delay = ms => new Promise(res => setTimeout(res, ms));
    
    async function firebaseAuth()
    {
        console.log(auth.currentUser)

        if(auth.currentUser === null)
        {
            signInAnonymously(auth)
            .then(() => {
                console.log("our new shiny user: " + auth.currentUser.uid)
                setUser(auth.currentUser)
                const docRef = doc(db,"Users", auth.currentUser.uid);
                setDoc(docRef, userObject).then(()=>{listenToAccount()})
            })
            .catch((error) => {
                alert(error.message);
            });
        }
        else
        {
            signInAnonymously(auth)
            .then(() => {
                listenToAccount()
            })
            .catch((error) => {
                alert(error.message);
            });
        }
    }

    async function login(type, email, password, )
    {
        let result = undefined
        try{
        if(type === "email")
        {
            result = await signInWithEmailAndPassword(auth, email, password)
        }
        else if(type === "google")
        {
            console.log("google")
            const provider = new GoogleAuthProvider();
            result = await signInWithRedirect(auth, provider);
            console.log(result)
        }
        else if(type === "facebook")
        {
            const provider = new FacebookAuthProvider();
            result = await signInWithRedirect(auth, provider);
        }
        else if(type === "x")
        {
            const provider = new TwitterAuthProvider();
            result = await signInWithRedirect(auth, provider);
        }
        else if(type === "apple")
        {
            const provider = new OAuthProvider('apple.com');
            result = await signInWithPopup(auth, provider);
        }}catch(e){
            console.log(e)
        }

        return result
    }
    async function resetPassword(email)
    {
        const result = await sendPasswordResetEmail(auth, email);
        return result
    }

    async function register(email, password)
    {
        let result = await createUserWithEmailAndPassword(auth, email, password)
        return result
    }
    
    async function initializeAccountDoc(username,bio){
        return await updateProfile(auth.currentUser, {
            displayName: username + specialUsernameWasSetString
          }).then(() => {
            const docRef = doc(db,"Users", username);
            setDoc(docRef, userObject(username, bio, auth.currentUser.uid)).then(()=>{listenToAccount(); return myAccount; }).catch((e)=> {return undefined})
          }).catch((error) => {
            return undefined
          });
    }

    let unsub = undefined, unsub2 = undefined

    function getMyUsername()
    {
        return auth.currentUser?.displayName?.replace(specialUsernameWasSetString, "") ?? "";
    }

    function listenToAccount(){
        if(unsub !== undefined) { unsub(); unsub = undefined;}

        const myUsername = getMyUsername();
        
        if(!auth.currentUser.displayName?.includes(specialUsernameWasSetString))
        {
            console.log("username not set yet")
            return;
        }

        unsub = onSnapshot(doc(db,"Users", myUsername), (doc) => {
            if(doc.exists()) setMyAccount(doc.data())
        });

        if(unsub2 !== undefined) { unsub2(); unsub2 = undefined; }

        unsub2 = onSnapshot(doc(db, "Users", myUsername, "Extra", "match_0"), (doc) => {
            if(doc.exists())
            {
                setMyMatches(doc.data())
                console.log(doc.data())
            }
            else{
                setMyMatches([])
            }
          });
      
          return unsub;
    }

    setTimeout(function () {
        requestAnimationFrame(onsnap)
    })

    async function onsnap(){
        const match = myMatches[curBoard]
        if(match && curBoard != "" && callback2)
        {
            callback2(match)
            setCurBoardState(match)
        }
    }

    function logout(){
        console.log("logout")
        signOut(auth)
    }

    async function checkUsernameExists(username)
    {
        const q = query(collection(db, "Users"), where("usernameLower", "==", username.toLowerCase()));
        const snapshot = await getCountFromServer(q);
        console.log("count: " + snapshot.data().count)
        return snapshot.data().count === 0
    }

    function getNextMatchNumber(myId, friendId)
    {
        let num = 0
        for (const key in myMatches) {
            if (key.split("|")[0]==myId&&key.split("|")[1]==friendId) {
                num = Math.max(num,parseInt(key.split("|")[2])+1)
            }
        }
        return num
    }

    function deleteMatch(matchId)
    {
        const friendId = matchId.replace(getMyUsername(), "").replace(matchId.split('|')[2], "").replaceAll("|","")
        updateDoc(doc(db,"Users", getMyUsername(), "Extra", "match_0"), { [matchId]: deleteField() })
        updateDoc(doc(db,"Users", friendId, "Extra", "match_0"), { [matchId]: deleteField() })
    }


    const value = {
        updateState,
        db,
        user,
        myAccount,
        login,
        register,
        logout,
        initializeAccountDoc,
        checkUsernameExists,
        createMatch,
        setCallback,
        setCurBoard,
        curBoard,
        myMatches,
        curBoardState,
        getMyUsername,
        setCurBoardState, num,deleteMatch,
        resetPassword
    };

    return (
    <DataContext.Provider value={value}>
        {children}
    </DataContext.Provider>
    );
}