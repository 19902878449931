import React, { useEffect, useState } from 'react'
import { useData } from "../services/firebase";
import '../styles/board.css'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { SiteRoutes } from '../routes';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Google } from '../assets/icons/google.svg';
import { ReactComponent as X } from '../assets/icons/x.svg';
import { ReactComponent as Apple } from '../assets/icons/apple2.svg';
import { ReactComponent as Facebook } from '../assets/icons/facebook.svg';

// import { NativeModules } from 'react-native'
// const { RNTwitterSignIn } = NativeModules;

const Login = () => {
  let {updateState, db, user, myAccount, login, register, resetPassword} = useData()
  let [authMode, setAuthMode] = useState("signin")

  let [email, setEmail] = useState("")
  let [password, setPassword] = useState("")
  let [password2, setPassword2] = useState("")

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin")
  }

  async function signin(e)
  {
    e.preventDefault(); 

    const res = await login("email", email, password)
    if(res === undefined) alert("Login failed")
  }

  async function resetPass(e)
  {
    e.preventDefault(); 

    const res = await resetPassword(email)
    if(res !== undefined) alert("reset failed " + res)
    else alert("reset email sent")
  }

  async function createAccount(e)
  {
    e.preventDefault(); 

    if(password !== password2)
    {
      alert("Passwords don't match")
      return;
    }

    const res = await register(email, password)
    console.log(res)
    if(res === undefined) alert("Registration failed")
  }

  return (
    <div className='Auth-form-container'>
    <form onSubmit={authMode === "password" ? resetPass : authMode === "signin" ? signin : createAccount} className="Auth-form">
    { 
    authMode === "password"?
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Reset Password</h3>
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control mt-4"
              placeholder="Email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="text-center mt-4">
            <a onClick={()=>setAuthMode("signin")} style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}}>Back to sign in</a>
          </p>
        </div>
    :
    
    authMode === "signin" ? 
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Sign In</h3>
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control mt-4"
              placeholder="Email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              value={password}
              required
              onChange={(e)=>setPassword(e.target.value)}
            />
          </div>
          <p className="text-center mt-4">
            <a onClick={()=>setAuthMode("password")} style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}}>Forgot password?</a>
          </p>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <div className="text-center mt-4">
            Not registered yet?{" "}
            <span style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}} onClick={changeAuthMode}>
              Register
            </span>
          </div>
        </div>
        :
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Register</h3>
          
          <div className="form-group mt-4">
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Email"
              value={email}
              required
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Password"
              value={password}
              required
              minLength={6}
              onChange={(e)=>setPassword(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Confirm Password"
              value={password2}
              required
              minLength={6}
              onChange={(e)=>setPassword2(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2 mt-4">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <div className="text-center mt-4">
            Already registered?{" "}
            <span style={{ cursor: "pointer", color: "#6699cc", fontWeight: "bold"}} onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
          {/* <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p> */}
        </div>}

        {
          authMode === "password" ? <></>
        :
        <>
        <div className="divider d-flex align-items-center my-4" style={{ marginInline: 30}}>
            <p className="text-center fw-bold mx-3 mb-0" style={{color:'#ffffffdd'}}>Or continue with</p>
        </div>

        
        <div className="d-flex align-items-center justify-content-center my-4">
          <Button onClick={()=>login("google")}  className='mx-3'style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <Google style={{width: 20, height: 20, marginBottom: 3}}/>
          </Button>

          <Button onClick={()=>login("apple")} className='mx-3' style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <Apple style={{width: 25, height: 25, marginBottom: 3, color: "black"}}/>
          </Button>
          
          {/* <Button onClick={()=>login("x")} className='mx-3' style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <X style={{width: 20, height: 20, marginBottom: 3}}/>
          </Button> */}

          {/* <Button onClick={()=>login("facebook")} className='mx-3'style={{ backgroundColor: 'white', borderColor: '#00000000'}}>
            <Facebook style={{width: 20, height: 20, marginBottom: 3}}/>
          </Button> */}
        </div>
        </>}
      </form>
    </div>
  )
}

export default Login