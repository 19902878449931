import { useRef, useEffect, useState } from "react";
import { Square } from "./square";
import { solve } from "../solvers/depthIterateAB";
import { updateDoc, collection } from "firebase/firestore";
import {getFirestore, useData } from "../services/firebase";

import { doc, onSnapshot } from "firebase/firestore";
import { unRankBoard } from "../utils/logic";

export function Board({boardWidth, boardId="", myAccountId, setBoardIdUpdatedCallback, onBoardUpdatedCallback}) {
  let { updateState, db, curBoardState, setCurBoardState, setCallback, num } = useData()
  const [state, setState] = useState([
    [0, 1, 1, 3, 1, 1, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0],
    [0, 2, 2, 4, 2, 2, 0],
  ])

  const [TTwin, setTTwin] = useState(new Set([]))
  const [TTloss, setTTloss] = useState(new Set([]))
  const [TTunknown, setTTunknown] = useState({})

  const [turn, setTurn] = useState(1)

  const [currentlySelected, setCurrentlySelected] = useState("")

  const COLUMNS = "abcdefgh".split("");
  const [boardOrientation, setOrientation] = useState('white')
  const [myTurn, setMyTurn] = useState(1)



  // callback = ()=>{
  //   console.log("doc updated")
  // }

  function onTurnEnd()
  {
    // runAI()
    if(boardId !== "") updateState(/*curBoardState.*/state,3-/*curBoardState.*/turn,boardId)
    setTurn(3-turn)
    onBoardUpdatedCallback(state)
  }

  function runAI()
  {
    solve(state, 10, 3-turn, TTwin, TTloss, TTunknown)
    setTTwin(TTwin)
    setTTloss(TTloss)
    setTTunknown(TTunknown)
  }


  const onBoardUpdated = (board)=>{
    console.log("callback " + board)
    syncBoard(board)
    return "BLA"
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  async function syncBoard(board)
  {
    if(board === undefined) return;

    console.log("SYNC")
    console.log(board["state"])
    
    delay(1); //todox is this really supposed to exist?  delays are bad
    
    if(board["mode"] === 1 || true)
      setState(unRankBoard(board["state"]))
    else
      { let copy = [...state]; for(let i = 0; i < 8; i++) copy[i] = board["state"][i+""]; setState(copy) }
    
    setTurn(board["turn"])
    setOrientation(board["isWhite"] ? 'white' : 'black')
    setMyTurn(board["isWhite"] ? 1 : 2)
    onBoardUpdatedCallback(unRankBoard(board["state"]))
  }

  console.log("board print : " + boardId)

  useEffect(() => {
    console.log("use effect : " + boardId + " :")
    setupCallback(boardId)
    if(setBoardIdUpdatedCallback) setBoardIdUpdatedCallback(() => (boardIDDD) => setupCallback(boardIDDD))

    // if(boardId !== "")
    //   setCallback(callback2)
    // if(boardId === "") 
    //   return;
    // // updateState(state,turn)
    // const unsub = onSnapshot(doc(db, "Users", myAccountId, "match_0"), (doc) => {
    //   // const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
    //   // console.log(source, " data: ", doc.data());
    //   if(doc.data()[boardId] == undefined) return
    //   let copy = [...state]
    //   for(let i = 0; i < 8; i++) copy[i] = doc.data()[boardId]["state"][i+""]
    //   setState(copy)
    //   setTurn(doc.data()[boardId]["turn"])
    //   setOrientation(doc.data()[boardId]["isWhite"] ? 'white' : 'black')
    //   setMyTurn(boardOrientation == 'black' ? 1 : 2)
    // console.log(copy)
    // });

    return ()=>{ setCallback(undefined);  console.log("callback cleared Board")} 
  }, []);

  function setupCallback(newBoardId){
    boardId = newBoardId
    console.log("setting up callback")
    if(boardId !== "") 
    { 
      console.log("callback updated Board")
      setCallback(() => (board) => onBoardUpdated(board))
      onBoardUpdated(curBoardState);
    }
  }

  console.log(boardId)

  return (
    <div 
      style={{
        backgroundColor: "yellow",
        // marginTop: 100,
        // marginLeft: 300
      }}
    >
      {[...Array(8)].map((_, r) => {
        return (
          <div
            key={r.toString()}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              maxWidth: boardWidth,
              // backgroundColor: "green",
              // marginLeft: boardWidth/2
            }}
          >
            {[...Array(7)].map((_, c) => {
              const square =
              /*curBoardState.*/boardOrientation === "black"
                  ? ((COLUMNS[6 - c] + (r + 1)))
                  : ((COLUMNS[c] + (8 - r)));
              const squareColor =  /*curBoardState.*/boardOrientation === "black" ? (c % 2 === r % 2 ? "white" : "black") :  (c % 2 === r % 2 ? "black" : "white");
              const piece = /*curBoardState.*/boardOrientation === "black" ? /*curBoardState.*/state[r][6-c] : /*curBoardState.*/state[7-r][c]
              return (
                <Square
                  key={`${c}${r}`}
                  square={square}
                  squareColor={squareColor}
                  piece={piece}
                  currentlySelected={currentlySelected}
                  setCurrentlySelected={setCurrentlySelected}
                  state={/*curBoardState.*/state}
                  setState={setState}//check this
                  boardOrientation={/*curBoardState.*/boardOrientation}
                  finishTurnCallback={onTurnEnd}
                  allowed={(piece % 2 == /*curBoardState.*/turn % 2||piece==0) && (boardId === ""  || turn == myTurn)}
                  boardWidth={boardWidth}
                />
              );
            })}
          </div>
        )
      })}
    </div>
  ) 
}

const boardStyles = (width) => ({
  cursor: "default",
  height: width,
  width,
});