import { ballMoveValid, moveValid } from "../utils/logic"

export function Square({
  square,
  squareColor,
  piece,
  children,
  currentlySelected,
  setCurrentlySelected,
  state, setState,
  boardOrientation,
  finishTurnCallback,
  allowed,
  boardWidth
}) {

  const pc = currentlySelected == "" ? 0 : state[parseInt(currentlySelected[1]-1)][parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0)))]

  
  const colors = {
    tileBlack: '#151515',
    tileWhite: '#8776BD',

    tileSelectedBlack: '#050505',
    tileSelectedWhite: '#8776BD',

    pieceBlack: '#6c6c6c',
    pieceWhite: '#90615A',

    ballBlack: '#593C1F',
    ballWhite: 'tan',

    legalPiece: 'pink',
    legalBall: 'pink',
  };

  // const colors = {
  //   tileBlack: "#505050",
  //   tileWhite:  "#B0B0B0",

  //   tileSelectedBlack: "#383838",
  //   tileSelectedWhite:  "#A0A0A0",

  //   pieceBlack: "#181818" ,
  //   pieceWhite: "#FFFFFF",

  //   ballBlack: "#888888",
  //   ballWhite: "#888888",
    
  //   legalPiece: "#55AA55",
  //   legalBall: "#55AA55",
  // }

  function onClick(){
    if(!allowed) {
        setCurrentlySelected("")
        return;
    }
    if(piece != 0)
    {
      if(currentlySelected == "")
      {
        // set currenlyt sel = square
        setCurrentlySelected(square)
      }else
      {
        // set cur = ""
        let copy = [...state]

        if(((pc == 3 || pc == 4) && (pc-piece) == 2) &&  ballMoveValid(state, pc, parseInt(currentlySelected[1]-1), parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0))),square[1]-1, parseInt((square.charCodeAt(0)-"a".charCodeAt(0))), [0,0,0,0,0]))
        {
          copy[parseInt(currentlySelected[1]-1)][parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0)))] = pc-2
          copy[parseInt(square[1]-1)][parseInt((square.charCodeAt(0)-"a".charCodeAt(0)))] = pc
          setState(copy)
          finishTurnCallback()
        }
        setCurrentlySelected("")
      }
    }else{
      if(currentlySelected != ""){
      let copy = [...state]
      if(pc < 3 && moveValid(parseInt(currentlySelected[1]-1), parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0))),square[1]-1, parseInt((square.charCodeAt(0)-"a".charCodeAt(0)))))
      {
        copy[parseInt(currentlySelected[1]-1)][parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0)))] = 0
        copy[parseInt(square[1]-1)][parseInt((square.charCodeAt(0)-"a".charCodeAt(0)))] = pc
        setState(copy)
        finishTurnCallback()
      }
    }
      setCurrentlySelected("")
        // if valid =>  and cur = ""
      // else cur = ""
    }
  }

  return (
    <div
    //   ref={drop}
      // style={defaultSquareStyle}
      data-square-color={squareColor}
      data-square={square}
      onClick={onClick}
      
      // style={{
      //   backgroundColor:"pink"
      // }}
    >
      {(
        <div
          // Type is too complex to properly evaluate, so ignore this line.
          // @ts-ignore
        //   ref={squareRef}
          style={{
            margin:0,
            padding:0,
            ...size(boardWidth),
            ...center,
            // ...(!squareHasPremove && customSquareStyles?.[square]),
          }}
        >
          {

            <div 
            style={{
              backgroundColor:currentlySelected == square ? (squareColor === "black" ? colors.tileSelectedBlack : colors.tileSelectedWhite) : (squareColor === "black" ? colors.tileBlack : colors.tileWhite),
              height: boardWidth/8,
              width: boardWidth/7,
              position: "relative",
              // display: "flex"
              // border: "solid",
              // borderWidth: currentlySelected == square ? 2 : 0,
              // borderColor: "tan"
            }}
            >
              {piece == 2 || piece == 4 || piece == 1 || piece == 3 ? 
              <svg style={{display: "block" }} fill={piece %2==0 ? colors.pieceBlack : colors.pieceWhite } viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M 6 6 L 6 26 L 26 26 L 26 6 L 6 6 z"/></svg>
              : <></>}
              
              { piece == 3 || piece == 4 ? 
              <svg style={{position: "absolute", top: 0, left: 0, }} height="100" width="100" xmlns="http://www.w3.org/2000/svg">
              <circle r={(2/75)*boardWidth} cx={boardWidth/16} cy={boardWidth/16} fill={piece == 3 ? colors.ballWhite : colors.ballBlack} />
              </svg>
              : (piece == 0 && pc < 3 && currentlySelected != "" && moveValid(parseInt(currentlySelected[1]-1), parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0))),square[1]-1, parseInt((square.charCodeAt(0)-"a".charCodeAt(0)))) ? 
              <svg style={{position: "absolute", top: 0, left: 0}} height="100" width="100" xmlns="http://www.w3.org/2000/svg">
              <circle r={(1/75)*boardWidth} cx={boardWidth/16} cy={boardWidth/16} fill={colors.legalPiece} />
              </svg>
              : (pc != 0 && (((pc == 3 || pc == 4) && (pc-piece) == 2) &&  ballMoveValid(state, pc, parseInt(currentlySelected[1]-1), parseInt((currentlySelected.charCodeAt(0)-"a".charCodeAt(0))),square[1]-1, parseInt((square.charCodeAt(0)-"a".charCodeAt(0))), [0,0,0,0,0])) ? 
              <svg style={{position: "absolute", top: 0, left: 0}} height="100" width="100" xmlns="http://www.w3.org/2000/svg">
              <circle r={(1/75)*boardWidth} cx={boardWidth/16} cy={boardWidth/16} fill={colors.legalBall} />
              </svg>: <></>))}
            </div>
          }
        </div>
      )}
    </div>
  );
}
//6d5e53
const center = {
  display: "flex",
  justifyContent: "center",
};

const size = (width) => ({
  width: width / 8,
  height: width / 8,
});

const borderRadius = (
  square,
  boardOrientation,
  customBoardStyle
) => {
  if (!customBoardStyle?.borderRadius) return {};

  // if (square === "a1") {
  //   return boardOrientation === "white"
  //     ? { borderBottomLeftRadius: customBoardStyle.borderRadius }
  //     : { borderTopRightRadius: customBoardStyle.borderRadius };
  // }
  // if (square === "a8") {
  //   return boardOrientation === "white"
  //     ? { borderTopLeftRadius: customBoardStyle.borderRadius }
  //     : { borderBottomRightRadius: customBoardStyle.borderRadius };
  // }
  // if (square === "h1") {
  //   return boardOrientation === "white"
  //     ? { borderBottomRightRadius: customBoardStyle.borderRadius }
  //     : { borderTopLeftRadius: customBoardStyle.borderRadius };
  // }
  // if (square === "h8") {
  //   return boardOrientation === "white"
  //     ? { borderTopRightRadius: customBoardStyle.borderRadius }
  //     : { borderBottomLeftRadius: customBoardStyle.borderRadius };
  // }

  return {};
};