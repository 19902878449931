import React from 'react';
import { Route, Switch, Redirect, Routes } from "react-router-dom";
// import Footer from '../components/Footer';
// import NavigationBar from '../components/NavigationBar';
import { DataProvider } from './services/firebase';
import { SiteRoutes } from "./routes";
import Home from './pages/home';
import Play from './pages/play';
import Login from './pages/login';

// const RouteNormal = ({ component: Component, ...rest }) => {

//   return (

//     <Route {...rest} render={props => (<> <Component {...props} /></>) } />
//     //<NavigationBar/> <Footer/>
//   );
// };


export default () => (
  

  // <AuthProvider>
  <DataProvider>
      <Routes>
        {/* <Route exact path={SiteRoutes.Home.path} component={Home} /> */}
        <Route path={SiteRoutes.Home.path} element={<Home/>} />
        <Route path={SiteRoutes.Play.path} element={<Play/>} />
        <Route path={SiteRoutes.Login.path} element={<Login/>} />

        {/* <Route exact path={SiteRoutes.Play.path} component={Play} /> */}
        {/* 
        <RouteNormal exact path={Routes.OurStory.path} component={our_story} />
        <RouteNormal exact path={Routes.OurStudents.path} component={our_students} />
        <RouteNormal exact path={Routes.OurTeam.path} component={our_team} />

        <RouteNormal path={Routes.ChooseDiscipline.path} component={choose_discipline} />
        <RouteNormal exact path={Routes.ChooseLevel.path} component={choose_level} />
        <RouteNormal path={Routes.DisciplineDetails.path} component={discipline_details} />
        <RouteNormal path={Routes.ProgramDetails.path} component={program_details} />
        <RouteNormal path={Routes.UniversityDetails.path} component={university_details} />
        <RouteNormal path={Routes.ApplicationForm.path} component={application_form} />
        <RouteNormal path={Routes.Search.path} component={search} />

        <RouteNormal exact path={Routes.Contact.path} component={contact} />
        <RouteNormal exact path={Routes.PrivacyPolicy.path} component={privacy_policy} />

        <RouteNormal exact path={Routes.NotFound.path} component={NotFoundPage} />
        */}

        {/* pages */}
        {/* <PrivateRoute exact path={Routes.DashboardOverview.path} component={DashboardOverview} /> */}

        {/* <Redirect to={Routes.NotFound.path} /> */}
      </Routes>
    </DataProvider>
);
