import React, { useEffect, useState } from 'react'
import { useData } from "../services/firebase";
import '../styles/board.css'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import { SiteRoutes } from '../routes';
import Login from './login';
import AccountCreation from './accountCreation';
import Modal from 'react-bootstrap/Modal';
const Home = () => {
  let {user, myAccount, logout, createMatch, myMatches, setCurBoard, loadCurBoard, getMyUsername, setCallback, setCurBoardState, deleteMatch} = useData()
  const [createModalShown, setCreateModalShown] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [friendId, setFriendId] = useState("")
  const [matches, setMatches] = useState({})
  const navigate = useNavigate();

  console.log(myAccount)
  // console.log("converted string is: '" + String.fromCharCode(0b00000011)+"'")

  const myUsername = getMyUsername();

  useEffect((()=>{
    setCallback(() => () => setMatches(myMatches))
    setMatches(myMatches)
    console.log("callback updated Home")
    localStorage.setItem("boardId", "")
      
      return ()=>{ setCallback(undefined);  console.log("callback cleared Home")} 
  }), [])

  // async function printYahoo(){
  //   console.log("Yahoo")
  // }

  async function challengeFriend(friendId)
  {
    setIsCreating(true)

    await createMatch(friendId)

    setIsCreating(false)

  }

  async function joinMatch(match, matchId)
  {
    console.log("setting match id " + match)
    setCurBoard(matchId)
    setCurBoardState(match)
    localStorage.setItem("boardId", matchId)
    // loadCurBoard(matchId)
    navigate(SiteRoutes.Play.path);
  }
  return (
    <>
    { user === null ? 
      <Login/>
      :
        myAccount === null ?
        <AccountCreation/> :
        <>
        <Modal as={Modal.Dialog} centered show={createModalShown}>
          <div style={{background: "#111", bordercolor: "grey", borderWidth: 1, borderStyle: "solid", borderRadius: 6}}>
          <Modal.Body>
          <h3 className="Auth-form-title">Challenge a Friend</h3>
            <div className="form-group mt-4">
              <input
                type="text"
                className="form-control mt-4"
                placeholder="Type friend's username"
                value={friendId}
                required
                onChange={(e)=>setFriendId(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="text-gray ms-auto" onClick={()=>setCreateModalShown(false)} style={{marginRight:"20px"}} disabled={isCreating}>
              Cancel
            </Button>
            <Button variant="primary" onClick={()=>challengeFriend(friendId)} disabled={isCreating}>
              Challenge
            </Button>
          </Modal.Footer>
          </div>
        </Modal>
      <div className='Auth-form-container'>
        <div className="Auth-form align-items-center justify-content-center">
        {/* <p className="mx-auto" style={{color: "white", marginBottom: 30}}>Five Knights</p> */}
        <p className='Auth-form-title'>Welcome {myAccount?.username ?? ""}</p>
        {/* <Button style={{width: "70%", marginLeft: "15%", marginBottom: 30}}>
          Play
        </Button> */}
        <Button as={Link} style={{width: "70%", marginLeft: "15%", marginBottom: 30}} to={SiteRoutes.Play.path}>
          Pass and Play
        </Button>
        <Button onClick={()=>setCreateModalShown(true)} style={{width: "70%", marginLeft: "15%", marginBottom: 30}}>
          Challenge a Friend
        </Button>
        <Button variant="secondary" onClick={()=>logout()} style={{width: "70%", marginLeft: "15%", marginBottom: 30}}>
          Log out
        </Button>

        {
          Object.keys(myMatches).length === 0 ?
          <></>
          :
          <>
        <h4 className='Auth-form-title'>
          My Matches:
        </h4>
        <br/>
        {
          Object.keys(myMatches).map((matchId) => {
            return (
              <div className= "d-flex">
              <Button onClick={()=>joinMatch(myMatches[matchId], matchId)} style={{display: "inline", marginInline: 20}} key={matchId} >
              {`Match #${matchId.split('|')[2]} with ${matchId.replace(myUsername, "").replace(matchId.split('|')[2], "").replaceAll("|","")}`}
              </Button>
              <Button className='ml-3' variant="secondary" onClick={()=> deleteMatch(matchId)}>
                Delete
              </Button>
              </div>
            )
          })
        }</>
      }
        </div>
    </div> </>}</>
  )
}

export default Home